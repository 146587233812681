import "core-js/modules/es6.number.constructor.js";
export default defineComponent({
  props: {
    positive: {
      type: Number,
      default: 0
    },
    negative: {
      type: Number,
      default: 0
    },
    score: {
      type: Number,
      default: 0
    }
  },
  setup: function setup() {}
});